import React, { Component } from 'react';
import HelmetEx from '../../components/helmet-ex';
import Layout from '../../components/layout';
import ServiceRow from '../../components/service-row';
import PorchShadesImage from '../../images/photos/products/porch-shades.png';
import SolarScreenPatioShadesImage from '../../images/photos/products/solar-screen-patio-shades.png';
import SubmenuList from '../../components/submenu-list';
import Config from '../../config';

class PatioAwningsShadesDrapes extends Component {
    render() {
        const route = Config.Services.ProductRoutes.FeaturedProjects;
        return (
            <Layout>
                <div className="patio-awnings-shades-drapes-page">
                    <HelmetEx>
                        <div metadata="title">Patio Awnings | Porch Shades | Solar Screen Shades | Elmwood Park NJ</div>
                        <div metadata="keywords">Porch,shades,solar screen,patio,bergen county,nj</div>
                        <div metadata="description">
                            Porch Shades and solar screen patio shades for your house
                        </div>
                    </HelmetEx>
                    <section className="header">
                        <div className="container">
                            <div className="content">
                                <div className="text">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <h1>PATIO AWNINGS, SHADES AND DRAPERS</h1>
                                            {/* <h3></h3> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="navigation-section">
                        <div className="container">
                            <div className="row">
                                <SubmenuList activePath={route} />
                            </div>
                        </div>
                    </section>
                    <section className="below-navigation-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ServiceRow flip title="PROCH SHADES" src={PorchShadesImage}>
                                        <p>
                                            Consider a porch shade so you can enjoy your porch,
                                            patio or family room all day long - even during the
                                            intense sun times of early morning or late afternoon
                                        </p>
                                    </ServiceRow>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ServiceRow title="SOLAR SCREEN PATIO SHADES" src={SolarScreenPatioShadesImage}>
                                        <p>
                                            Minimize the glare, while maintaining your view, with solar
                                            screen patio shades, in a range of weaves, colors and styles.
                                        </p>
                                    </ServiceRow>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}

export default PatioAwningsShadesDrapes;